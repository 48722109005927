import { MediaData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../components/View';
import Form from '../../../../../containers/Espace/Form';
import params from '../../../../../params/media.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const PageEspaceMediaUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, id, parentCollectionName, parentId },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        displayAdd={false}
        displayNavigation={false}
        displayView={false}
        docId={id}
        itemPathnamePrefix={`/espaces/${espaceId}/medias/`}
        model={
          new MediaData({
            params,
            parentCollectionName,
            parentId,
            espaceId: espace.id,
            itemFieldTitle: 'name',
          })
        }
        name="media"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);
export default requireEspace(PageEspaceMediaUpdate);
